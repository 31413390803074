'use client';
import React, { useState, useRef } from 'react';
import { useTranslation } from '@dreamplan/i18n';
import { useAnalytics } from '@dreamplan/shared-ui/client/analytics';
import { AppLocale } from '@dreamplan/types/src/EdgeStore';
import { Bars3Icon, UserCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useRouter } from 'next/navigation';
import { socialInfo } from '../../constants/info';
import { ContactInfo, LinksType, Headers } from '../../types/CommonTypes';
import useFirstInteraction from '../../utils/useFirstInteraction';
import { useOnClickOutside } from '../../utils/useOnClickOutside';
import { Button } from '../Button';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import Logo from '../Logo';
import PrintPDFButton from '../PrintPDFButton';
import { Text } from '../Typography';
import { CartButton } from './CartButton';
import MainNavigation from './MainNavigation';
import MobileNavigation from './MobileNavigation';

export const initialLinks = { main: [], other: [], app: socialInfo.app };

type ButtonProps = { text: string; link: string };
export type AuthNavs = {
  signIn: ButtonProps;
  signUp: ButtonProps;
  signOut: ButtonProps;
  user:
    | {
        name: string;
        email: string;
        type: 'signed-in';
        isGuest?: boolean;
      }
    | {
        type: 'unsigned';
      }
    | {
        type: 'loading';
      };
};

export type NavBarProps = {
  links?: LinksType;
  auth: AuthNavs;
  info: ContactInfo;
  headers: Headers;
  logoNavigation?: string;
  disableClick?: boolean;
  redirectOnProfileClick?: string; // workaround to avoid openning full menu on desktop
  isCartEnabled?: boolean;
  isPDFButtonEnabled?: boolean;
  locales: AppLocale[];
};

const Navbar = (props: NavBarProps) => {
  const {
    links = initialLinks,
    info,
    headers,
    logoNavigation,
    auth,
    disableClick = false,
    redirectOnProfileClick,
    isCartEnabled = false,
    isPDFButtonEnabled,
    locales,
  } = props;
  const [openMenu, setOpenMenu] = useState(false);

  const isFirstInteraction = useFirstInteraction();
  const router = useRouter();
  const node = useRef() as any;
  // FIXME: it not a good practice to access context provider in core component directly
  const { t } = useTranslation('navigation');
  const { track } = useAnalytics();
  useOnClickOutside(node, () => setOpenMenu(false));

  return (
    <header className={clsx('z-[1000] w-full px-4 py-3', 'bg-white')} ref={node}>
      <div className="mx-auto max-w-screen-xl">
        <div className={clsx('flex w-full items-center justify-between text-base', 'text-black')}>
          <div>
            <a href={logoNavigation ?? '/'} aria-label={t('logo_aria_label')}>
              <Logo fill={'black'} width={60} height={60} />
            </a>
          </div>

          {!disableClick && links.main && (
            <MainNavigation
              links={links.main}
              className="hidden flex-1 items-center gap-x-8 lg:flex lg:flex-row"
              locales={locales}
              auth={auth}
              isPDFButtonEnabled={isPDFButtonEnabled}
            />
          )}
          <div className="visible flex items-center gap-x-3 lg:invisible print:invisible">
            <LanguageSwitcher
              onOpen={() => {
                track('LanguageSelectorClick', {});
              }}
              title={t('logo_aria_label')}
              locales={locales}
            />
            <PrintPDFButton auth={auth} isPDFButtonEnabled={isPDFButtonEnabled} />

            {auth.user.type === 'signed-in' && <CartButton />}
            <button
              aria-label={t('mobile_menu_aria_label')}
              onClick={() => setOpenMenu(!openMenu)}
              className="cursor-pointer lg:hidden print:hidden"
            >
              <Bars3Icon height={45} width={45} fill={'white'} />
            </button>
          </div>
          <div className="hidden min-w-[120px] justify-end gap-x-4 lg:flex">
            {auth.user.type === 'signed-in' && (
              <div className="flex">
                <CartButton className="mr-2" />
                {!auth.user.isGuest && (
                  <Text className="align-center pr-2 pt-1 font-semibold">{auth.user.name}</Text>
                )}
                <UserCircleIcon
                  className="cursor-pointer"
                  onClick={() => {
                    if (redirectOnProfileClick != null) {
                      router.push(redirectOnProfileClick);
                    } else {
                      setOpenMenu(true);
                    }
                    track('ProfileClick', {});
                  }}
                  height={33}
                  width={33}
                />
              </div>
            )}

            {auth.user.type === 'unsigned' && !disableClick && (
              <>
                <Button asChild className="border-2 px-8 py-2 font-semibold" variant="outlined">
                  <a href={auth.signIn.link}>{auth.signIn.text}</a>
                </Button>
                <Button asChild className="px-8 py-2 font-semibold" variant="primary">
                  <a href={auth.signUp.link}>{auth.signUp.text}</a>
                </Button>
              </>
            )}

            {auth.user.type === 'loading' && (
              <div className="flex w-full max-w-xs animate-pulse items-center gap-x-2">
                <div className="h-10 w-40 rounded-full bg-gray-400 px-8 py-2"></div>
                <div className="h-10 w-40 rounded-full bg-gray-400 px-8 py-2"></div>
              </div>
            )}
          </div>
        </div>
        {isFirstInteraction && (
          <MobileNavigation
            links={links}
            isOpen={openMenu}
            setOpenMenu={setOpenMenu}
            info={info}
            headers={headers}
            auth={auth}
            disableClick={disableClick}
          />
        )}
      </div>
    </header>
  );
};

export default Navbar;
