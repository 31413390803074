import { Pages } from '../../ui/types/Locales';

export const mainPages: Pages = {
  da: [
    {
      page: 'Om løsningen',
      navigation: '/loesning',
    },
    {
      page: 'Om os',
      navigation: '/om-os',
    },
    {
      page: 'Artikler',
      navigation: '/artikler',
    },
    {
      page: 'Karriere',
      navigation: '/jobs',
    },
    {
      page: 'Kontakt',
      navigation: '/kontakt-os',
    },
  ],
  en: [
    {
      page: 'About our app',
      navigation: '/solution',
    },
    {
      page: 'About Us',
      navigation: '/about-us',
    },
    {
      page: 'Articles',
      navigation: '/articles',
    },
    {
      page: 'Careers',
      navigation: '/careers',
    },
    {
      page: 'Contact',
      navigation: '/contact',
    },
  ],
  es: [
    {
      page: 'Sobre nuestra app',
      navigation: '/solucion',
    },
    {
      page: 'Acerca de',
      navigation: '/acerca-de',
    },
    {
      page: 'Artículos',
      navigation: '/articulos',
    },
    {
      page: 'Carreras',
      navigation: '/carreras',
    },
    {
      page: 'Contacto',
      navigation: '/contacto',
    },
  ],
  no: [
    {
      page: 'Om appen vår',
      navigation: '/loesning',
    },
    {
      page: 'Om oss',
      navigation: '/om-oss',
    },
    {
      page: 'Artikler',
      navigation: '/artikler',
    },
    {
      page: 'Karriere',
      navigation: '/karriere',
    },
    {
      page: 'Kontakt',
      navigation: '/kontakt',
    },
  ],
};
