import { ContactInfo, SocialInfo } from '../types/CommonTypes';

export const contactInfo: ContactInfo = {
  address: 'Applebys Pl. 7, 1411 København',
  phone: '+ 45 26 13 61 95',
  email: 'kundeservice@dreamplan.io',
  followText: 'Følg os på sociale medier',
};

export const socialInfo: SocialInfo = {
  app: 'https://www.dreamplan.io',
  linkedIn: 'https://www.linkedin.com/company/dreamplan',
  facebook: 'https://www.facebook.com/DreamPlan.io',
  instagram: 'https://www.instagram.com/dreamplan.dk',
};
