'use client';
import * as React from 'react';
import { useTranslation } from '@dreamplan/i18n';
import { UserCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { socialInfo } from '../../constants/info';
import { Headers, ContactInfo, LinksType } from '../../types/CommonTypes';
import useScrollLock from '../../utils/useScrollLock';
import { Button } from '../Button';
import Contact from '../Contact';
import PageLink from '../PageLink';
import { Text } from '../Typography';
import { AuthNavs, initialLinks } from './Navbar';

interface Props {
  className: string;
  links?: LinksType;
  info: ContactInfo;
  headers: Headers;
  disableClick?: boolean;
  isWebsite?: boolean;
  onCloseMenu: () => void;
  isOpen: boolean;
  auth: AuthNavs;
}

const BurgerMenu = ({
  className,
  links = initialLinks,
  info,
  headers,
  disableClick,
  isWebsite,
  onCloseMenu,
  isOpen,
  auth,
}: Props) => {
  const { contact, menu, other } = headers;
  const { t } = useTranslation('navigation');
  useScrollLock(isOpen);

  return (
    <div className={clsx(className, 'mt-24 overflow-y-scroll px-7 py-3')}>
      <div className="flex items-center justify-between border-b-[1px] border-gray-300 pb-3">
        {auth.user.type === 'signed-in' && (
          <div className="flex gap-x-1">
            <UserCircleIcon className="cursor-pointer" height={33} width={33} />
            <div className="inline-flex flex-col items-start justify-center">
              <Text className="font-medium">{auth.user.name}</Text>
              {auth.user?.email && auth.user.email !== 'guest-user@dreamplan.io' && (
                <Text variant={'xsmall'} className="truncate md:max-w-[250px]">
                  {auth.user?.email}
                </Text>
              )}
            </div>
          </div>
        )}

        {auth.user.type === 'unsigned' && !disableClick && (
          <>
            <Button asChild className="border-2 px-8 py-2 font-semibold" variant="outlined">
              <a href={auth.signIn.link}>{auth.signIn.text}</a>
            </Button>
            <Button asChild className="ml-2 px-8 py-2 font-semibold" variant="primary">
              <a href={auth.signUp.link}>{auth.signUp.text}</a>
            </Button>
          </>
        )}

        {auth.user.type === 'loading' && (
          <div className="flex animate-pulse items-center gap-x-2">
            <UserCircleIcon className="cursor-pointer opacity-70" height={33} width={33} />
            <div className="h-6 w-32 bg-gray-400"></div>
          </div>
        )}

        <button aria-label={t('close_side_menu_aria_label')} onClick={onCloseMenu}>
          <XMarkIcon className="h-5 w-5 cursor-pointer" strokeWidth={2.2} />
        </button>
      </div>
      {links.main && (
        <nav className="flex flex-col space-y-2 py-3">
          <h2 className="pb-0 text-lg font-medium">{menu}</h2>
          <ul className="m-0 flex list-none flex-col gap-y-2">
            {links.main.map((link, index) => (
              <li key={index}>
                <PageLink
                  link={link}
                  disableClick={disableClick}
                  isWebsite={isWebsite}
                  onClick={onCloseMenu}
                />
              </li>
            ))}
          </ul>
        </nav>
      )}
      {auth.user.type === 'signed-in' && (
        <div className="py-3">
          <a
            className={
              'block cursor-pointer border-y border-gray-100 py-3 font-medium text-gray-800 hover:opacity-70'
            }
            aria-current={undefined}
            href={auth.signOut.link}
          >
            {auth.signOut.text}
          </a>
        </div>
      )}
      {links.other && links.other.length > 0 ? (
        <nav className="flex flex-col space-y-4 border-b-[1px] pb-9 pt-2">
          <h2 className="pb-0 text-lg font-medium">{other}</h2>
          <ul className="m-0 flex list-none flex-col gap-y-1 p-0">
            {links.other.map((link, index) => (
              <li key={index}>
                <PageLink
                  link={link}
                  disableClick={disableClick}
                  isWebsite={isWebsite}
                  onClick={onCloseMenu}
                />
              </li>
            ))}
          </ul>
        </nav>
      ) : null}
      <div className="flex flex-col space-y-3 py-4">
        <h2 className="pb-0 text-lg font-medium">{contact}</h2>
        <Contact
          className="flex flex-col fill-zinc-800"
          info={info}
          socials={socialInfo}
          mediaIconsColor="black"
        />
      </div>
    </div>
  );
};

export default BurgerMenu;
