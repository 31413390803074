import * as React from 'react';
import { useTranslation } from '@dreamplan/i18n';
import { AtSymbolIcon, DocumentTextIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { ContactInfo, SocialInfo } from '../../types/CommonTypes';
import { LinkedInIcon, FacebookIcon, InstagramIcon } from '../Icons';
import { Text } from '../Typography';

type IconColor = 'black' | 'white';

interface ContactProps {
  className: string;
  info: ContactInfo;
  socials: Omit<SocialInfo, 'app'>;
  iconColor?: IconColor;
  mediaIconsColor?: IconColor;
}

const Contact = (props: ContactProps) => {
  const { className, info, socials, iconColor = 'black', mediaIconsColor } = props;
  const { address, email, followText, phone } = info;
  const { facebook, linkedIn, instagram } = socials;
  const iconSize = 18;
  const color = iconColor === 'black' ? 'text-black' : 'text-white';
  const mediaIcons = mediaIconsColor === 'black' ? 'black' : 'white';
  const { t } = useTranslation('navigation');
  return (
    <div className={className}>
      <article className="flex flex-col gap-y-4 pb-4">
        <a
          href={`http://maps.google.com/?q=1200 ${address} DK`}
          className="flex items-center space-x-2"
          target="_blank"
          rel="noreferrer"
          aria-label={t('map_location_aria_label') + ` ${address}`}
        >
          <MapPinIcon width={iconSize} height={iconSize} className={clsx(color)} />
          <span className="text-sm">{address}</span>
        </a>
        <a
          href={`mailto:${email}`}
          className="flex items-center space-x-2"
          aria-label={t('send_email_aria_label') + ` ${email}`}
        >
          <AtSymbolIcon width={iconSize} height={iconSize} className={clsx(color)} />
          <span className="text-sm">{email}</span>
        </a>
        <a
          href={`tel:${phone.replace(' ', '')}`}
          className="flex items-center space-x-2"
          aria-label={t('call_us_aria_label') + ` ${phone}`}
        >
          <PhoneIcon width={iconSize} height={iconSize} className={clsx(color)} />
          <span className="text-sm">{phone}</span>
        </a>
        <article className="flex items-center space-x-2">
          <DocumentTextIcon width={iconSize} height={iconSize} className={clsx(color)} />
          <span className="text-sm">CVR 40965874</span>
        </article>
      </article>
      <section className="flex flex-col self-start border-t pt-4">
        <Text className="font-semibold">{followText}</Text>
        <div className="flex items-center space-x-5 pt-2">
          <a href={linkedIn} aria-label={t('socials_aria_label') + ' Linkedin'}>
            <LinkedInIcon
              width={53}
              height={53}
              className={'h-20 w-20'}
              fill={mediaIcons}
              stroke={mediaIcons}
            />
          </a>

          <a href={facebook} aria-label={t('socials_aria_label') + ' Facebook'}>
            <FacebookIcon
              height={53}
              width={53}
              className={'h-20 w-20'}
              fill={mediaIcons}
              stroke={mediaIcons}
            />
          </a>

          <a href={instagram} aria-label={t('socials_aria_label') + ' Instagram'}>
            <InstagramIcon
              height={53}
              width={53}
              className="h-20 w-20"
              fill={mediaIcons}
              stroke={mediaIcons}
            />
          </a>
        </div>
      </section>
    </div>
  );
};

export default Contact;
