import { useEffect } from 'react';

export const useOnClickOutside = (
  ref: React.MutableRefObject<HTMLElement>,
  handler: CallableFunction,
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      if (!ref.current || ref.current.contains(event?.target as HTMLElement)) {
        return;
      }

      handler();
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};
