import * as React from 'react';
import { useTranslation } from '@dreamplan/i18n';
import { useAnalytics } from '@dreamplan/shared-ui/client/analytics';
import { AppLocale } from '@dreamplan/types/src/EdgeStore';
import { PageLinkType } from '../../types/CommonTypes';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { AuthNavs } from '../Navbar/Navbar';
import PageLink from '../PageLink';
import PrintPDFButton from '../PrintPDFButton';

interface Props {
  className?: string;
  links?: PageLinkType[];
  locales: AppLocale[];
  auth: AuthNavs;
  isPDFButtonEnabled?: boolean;
}

const MainNavigation = ({
  className = '',
  links = [],
  locales = [],
  auth,
  isPDFButtonEnabled,
}: Props) => {
  // FIXME: it not a good practice to access context provider in core component directly
  const { t } = useTranslation('navigation');
  const { track } = useAnalytics();
  return (
    <nav>
      <ul className={`${className} m-0 list-none`}>
        {links.map((link, index) => (
          <li key={index} className="xl:text-lg">
            <PageLink link={link} />
          </li>
        ))}
        <LanguageSwitcher
          onOpen={() => {
            track('LanguageSelectorClick', {});
          }}
          title={t('language_switch_headline')}
          locales={locales}
        />
        <PrintPDFButton auth={auth} isPDFButtonEnabled={isPDFButtonEnabled} />
      </ul>
    </nav>
  );
};

export default MainNavigation;
