'use client';
import * as React from 'react';
import { useAnalytics } from '@dreamplan/shared-ui/client/analytics';
import clsx from 'clsx';
import { LocaleLink } from '../LocaleLink';
import { CartIcon } from '@dreamplan/ui';

export function CartButton({ className }: { className?: string }) {
  const [cartCount, setCartCount] = React.useState(0);
  const { track } = useAnalytics();

  React.useEffect(() => {
    const fn = () => {
      const rawCartProgress = window.localStorage.getItem('cart-progress');
      const cartProgress = rawCartProgress ? JSON.parse(rawCartProgress) : null;
      if (cartProgress) {
        const count =
          cartProgress.total -
          (cartProgress?.disabledProducts?.length || 0) +
          (cartProgress?.completedProducts?.length || 0);
        setCartCount(count);
      } else {
        setCartCount(0);
      }
    };

    fn();

    return document.addEventListener('cart-progress', () => {
      fn();
    });
  });

  return (
    <LocaleLink href={`/cart`} className={clsx('relative block', className)}>
      <CartIcon width={33} height={33} />
      {cartCount > 0 && (
        <div
          onClick={() => {
            track('ClickCart', {});
          }}
          className="absolute bottom-0 right-0 inline-flex h-5 w-5 items-center justify-center rounded-full bg-black text-white"
        >
          <span>{cartCount}</span>
        </div>
      )}
    </LocaleLink>
  );
}
