import * as React from 'react';
import clsx from 'clsx';
import { ContactInfo, LinksType, Headers } from '../../types/CommonTypes';
import { AuthNavs, initialLinks } from '../Navbar/Navbar';
import BurgerMenu from './BurgerMenu';

interface Props {
  isOpen: boolean;
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
  links?: LinksType;
  auth: AuthNavs;
  info: ContactInfo;
  headers: Headers;
  disableClick?: boolean;
  isWebsite?: boolean;
}

const MobileNavigation = ({
  isOpen,
  setOpenMenu,
  links = initialLinks,
  auth,
  info,
  headers,
  disableClick,
  isWebsite,
}: Props) => {
  return (
    <nav>
      <BurgerMenu
        auth={auth}
        isOpen={isOpen}
        onCloseMenu={() => setOpenMenu(false)}
        className={clsx(
          'fixed right-0 top-0 z-[1000] h-screen overflow-auto bg-white shadow-lg transition lg:h-fit',
          '-mx-1 rounded-bl-3xl rounded-tl-3xl text-zinc-800 delay-150 ease-in-out',
          !isOpen ? `translate-x-full` : 'translate-x-0',
        )}
        links={links}
        info={info}
        headers={headers}
        disableClick={disableClick}
        isWebsite={isWebsite}
      />
    </nav>
  );
};

export default MobileNavigation;
