'use client';
import * as React from 'react';
import { useAnalytics } from '@dreamplan/shared-ui/client/analytics';
import clsx from 'clsx';
import { useParams, usePathname } from 'next/navigation';
import { PageLinkType } from '../../types/CommonTypes';

interface Props {
  link: PageLinkType;
  disableClick?: boolean;
  isWebsite?: boolean;
  onClick?: () => void;
}

const PageLink = ({ link, disableClick, isWebsite = true, onClick }: Props) => {
  const { page, navigation } = link;
  const pathname = usePathname();
  const params = useParams();
  const { track } = useAnalytics();
  const isActive = navigation.match(pathname) != null;
  const localePrefixed = React.useMemo(() => {
    const localePath = `/${params['locale']}`;
    if (params['locale'] === 'da') {
      return navigation;
    } else if (navigation.startsWith(localePath)) {
      return navigation;
    } else {
      return `${localePath}${navigation}`;
    }
  }, [navigation]);

  return (
    <>
      {isWebsite ? (
        <a
          key={page}
          href={localePrefixed}
          className={clsx(
            {
              'cursor-pointer py-5  hover:opacity-70': !disableClick,
            },
            {
              'pointer-events-none cursor-pointer py-5 hover:opacity-70': disableClick,
            },
            isActive && 'font-semibold underline',
          )}
          onClick={() => {
            onClick?.();
            track('MenuClick', { link: localePrefixed, page: page });
          }}
        >
          {page}
        </a>
      ) : (
        <a
          className={clsx(
            {
              'cursor-pointer py-5 hover:opacity-70': !disableClick,
            },
            {
              'pointer-events-none cursor-pointer py-5 hover:opacity-70': disableClick,
            },
            isActive && 'font-semibold underline',
          )}
          href={`/${params['locale']}${navigation}`}
          onClick={() => {
            onClick?.();
            track('MenuClick', { link: localePrefixed, page: page });
          }}
        >
          {page}
        </a>
      )}
    </>
  );
};

export default PageLink;
