'use client';
import * as React from 'react';
import { PrinterIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useSearchParams } from 'next/navigation';
import { Button } from '../Button';
import { AuthNavs } from '../Navbar/Navbar';

/**
 * All auth references may be removed once the PDF service supports unauthenticated users
 * Also from {@link ../MainNavigation}, both used in {@link ../Navbar}
 *
 * Currently not visible in production. Applied a 60 second delay to button to prevent
 * denial of service due to PDF service limitations.
 *
 * Page must have a defined pageStatus property from {@link ../utils/usePageReady}
 * for the button to appear. Without knowing if the page is ready,
 * printing would fail as of the making of this component.
 */
function PrintPDFButton({
  auth,
  isPDFButtonEnabled,
}: {
  auth: AuthNavs;
  isPDFButtonEnabled?: boolean;
}) {
  const params = useSearchParams();
  const [status, setStatus] = React.useState<
    { status: 'enabled'; link: string } | { status: 'disabled' }
  >({ status: 'disabled' });
  const [onCooldown, setOnCooldown] = React.useState<boolean>(false);
  const delay = 60000; //milliseconds
  const showButton =
    status.status === 'enabled' && auth.user.type === 'signed-in' && isPDFButtonEnabled;

  const getPDFLink = () => {
    const { href } = window.location;
    const fullPath = href.split('?')[0] as string;
    const urlWithQueryParams = new URL(fullPath);
    urlWithQueryParams.searchParams.set('print', 'true');

    params?.forEach((query) => {
      const value = params.get(query);
      if (typeof value === 'string') {
        urlWithQueryParams.searchParams.set(query, value);
      }
    });

    const searchParams = new URLSearchParams();
    searchParams.append('urls', urlWithQueryParams.toString());
    searchParams.append('name', urlWithQueryParams.pathname.split('/').pop() || 'dreamplan');
    const pdfApi = `/api/pdf?${searchParams}`;
    return pdfApi;
  };

  const ref = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    return () => {
      ref.current != null ? clearTimeout(ref.current) : null;
    };
  }, []);

  const handleCooldown = () => {
    if (!onCooldown) {
      setOnCooldown(true);
      ref.current = setTimeout(() => setOnCooldown(false), delay);
    }
  };

  React.useEffect(() => {
    return window.addEventListener('Custom.pageReady', () => {
      if ((window as any).pageStatus === 'ready') {
        setStatus({
          status: 'enabled',
          link: getPDFLink(),
        });
      }
    });
  }, []);

  return showButton ? (
    <Button
      variant={null}
      className={clsx('hover:text-gray-300', onCooldown && 'text-gray-250 pointer-events-none')}
      size={'none'}
      aria-disabled={onCooldown}
      onClick={() => {
        handleCooldown();
      }}
      asChild
    >
      <a href={status.link}>
        <PrinterIcon className="h-7 w-7" />
      </a>
    </Button>
  ) : null;
}
export default PrintPDFButton;
