/* eslint-disable @next/next/no-img-element */
'use client';
import * as React from 'react';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { mainPages } from '../../../constants/locales';
import { Pages } from '../../types/Locales';
import { useLocale } from '../../utils/useLocale';
import Modal from '../Modal';
import { Text } from '../Typography';

type BaselineLocaleInfo = {
  locale: string;
  preview: string;
  strictRedirect?: string;
};

type LocaleItem = {
  locale: string;
  preview: string;
  flag: string;
  path: string;
};

const determinePath = (currentLocale: string, targetLocale: string, path: string) => {
  const normalizedPath = path.replace(new RegExp(`/${currentLocale}(?=/)`), '');

  if (normalizedPath === '/') return `/${targetLocale === 'da' ? '' : targetLocale}`;
  const index = mainPages[currentLocale as keyof Pages]
    .map((page) => page.navigation)
    .indexOf(normalizedPath);

  // If the current page is not in the main navigation, just add the locale to the path
  if (index === -1) return `/${targetLocale}${normalizedPath}`;
  const targetLinks = mainPages[targetLocale as keyof Pages];
  const link = targetLinks[index]?.navigation;

  return link ? `/${targetLocale}${link}` : `/${targetLocale}`;
};

const toLocaleItems = (
  locales: BaselineLocaleInfo[],
  pathname: string,
  currentLocale: string,
): LocaleItem[] => {
  return locales.map(({ locale, preview, strictRedirect }) => ({
    locale,
    preview,
    flag: `/assets/flags/${locale}.svg`,
    path: strictRedirect ?? determinePath(currentLocale ?? 'da', locale, pathname),
  }));
};

export default function LanguageSwitcher({
  locales,
  title,
  onOpen,
}: {
  locales: BaselineLocaleInfo[];
  title: string;
  onOpen: () => void;
}) {
  const [isPickerOpen, setIsPickerOpen] = React.useState(false);
  const pathname = usePathname();
  const locale = useLocale();
  const supportedLanguages = toLocaleItems(locales, pathname, locale);

  return (
    <>
      <button
        onClick={() => {
          setIsPickerOpen(true);
          onOpen();
        }}
      >
        <GlobeAltIcon className="h-7 w-7" />
      </button>

      {isPickerOpen && (
        <Modal onClose={() => setIsPickerOpen(false)} className="bg-secondary">
          <div className="flex w-full flex-col items-center justify-center pt-10">
            <Text variant={'h2'}>{title}</Text>
            <div className="mt-12 flex flex-col gap-y-6 overflow-y-auto">
              {supportedLanguages.map((language) => (
                <Link
                  href={language.path}
                  key={language.locale}
                  className={clsx(
                    'flex items-center gap-x-4 rounded-full px-6 py-3',
                    language.locale === locale
                      ? 'bg-primary text-white'
                      : 'border-black bg-white text-black',
                    'inline-flex items-center',
                  )}
                >
                  <img src={language.flag} alt={language.preview} width={20} height={20} />
                  <Text variant={'large'} className="font-medium">
                    {language.preview}
                  </Text>
                </Link>
              ))}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
